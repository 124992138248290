<template>
    <div class="dashboard">
        <FileUpload></FileUpload>
    </div>
</template>

<script>
import FileUpload from '@/components/file-upload/fileupload'
export default {
    name: 'FileUploadPage',
    components: {
        FileUpload
    },
    methods: {

    },
}
</script>