<template>
    <div class="flex xs12">
        <va-card>
            <va-card-title>{{ $t('fileUpload.uploadFile') }}</va-card-title>
            <va-card-content>
                <dashboard :uppy="setUppy" />
            </va-card-content>
        </va-card>
    </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex"
import Uppy from '@uppy/core'

import German from '@uppy/locales/lib/de_DE'
import English from '@uppy/locales/lib/en_US'
import Dutch from '@uppy/locales/lib/nl_NL'

import XHRUpload from '@uppy/xhr-upload'
import Tus from '@uppy/tus'
import { Dashboard, ProgressBar } from '@uppy/vue'
import Dropbox from '@uppy/dropbox'
import OneDrive from '@uppy/onedrive'

import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'



export default {
    name: 'fileupload',
    components: {
        Dashboard
    },
    data() {
        return {
            uppy: null
        }
    },
    methods: {
        ...mapActions("files", { uploadFileRequest: "uploadFileRequest" }),
    }, computed: {
        ...mapState("files", {
            isUploading: "isUploading", actionState: "actionState"
        }),
        setUppy() {
            this.uppy = new Uppy({}).use(Tus,
                {
                    endpoint: process.env.VUE_APP_BACKEND + 'api/uploads/resumable/',
                    retryDelays: [0, 1000, 3000, 5000],
                    formData: true,
                    fieldName: 'files',
                    removeFingerprintOnSuccess: true

                })
            return this.uppy
        }
    },
    mounted() {

    },
    watch: {
        actionState(value, oldValue) {
            this.$moshaToast({ title: this.actionState.title, description: this.actionState.message }, { type: this.actionState.result, position: 'top-center', timeout: 2000 })
            this.uploadedFiles = []
        }
    }
}
</script>

<style scoped>
.upload_actions {
    margin-top: 30px;
    text-align: center;
}
</style>
